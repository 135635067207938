<template>
  <div class="dialog-container">
    <!-- FIRST PART REPORTS THAT WE ALREADY OPENED -->
    <!-- THE SECOND PART IS THE TABLES THAT WE CHECKBOXED -->
    <div class="dialog overflow-hidden">
      <div class="header">
        <span class="title">{{ $t('reports.сomposite_report') }}</span>
        <i
          @click="$emit('close')"
          class="el-icon-close text-2xl cursor-pointer text-darkblue font-semibold"
        ></i>
      </div>
      <div class="main">
        <div v-if="openedReports.length" class="border-main content">
          <template>
            <div v-for="(table, index) in openedReports" :key="index">
              <el-checkbox class="checkbox" v-model="table.isSelected">
                <div class="input-content flex flex-col">
                  <span class="title mb-1 truncate w-72">
                    {{
                      table.name ||
                      '' + ',' + table.unit.name ||
                      formData.tableData.group_name
                    }}
                  </span>
                  <span class="sub-title">
                    {{ dates.from + '—' + dates.to }}
                  </span>
                </div>
              </el-checkbox>
            </div></template
          >
        </div>
        <div class="map mb-1">
          <el-checkbox v-model="isPrintMap">
            <span class=" ">{{ $t('reports.map_snapshot') }}</span>
          </el-checkbox>
        </div>
        <div class="content second overflow-y-auto">
          <template>
            <div v-for="(table, index) in currentTables" :key="index">
              <el-checkbox class="checkbox" v-model="table.isSelected">
                <div class="input-content flex flex-col">
                  <span class="title mb-1 truncate w-72">
                    {{ table.name || '' + ',' + currentUnit.name || '' }}
                  </span>
                  <span class="sub-title">
                    {{ dates.from + '—' + dates.to }}
                  </span>
                </div>
              </el-checkbox>
            </div>
          </template>
        </div>
      </div>
      <div class="footer">
        <el-select class="btn" v-model="format" collapse-tags="collapse-tags">
          <el-option
            class="text-ellipsis"
            style="max-width: 265px"
            v-for="item in formats"
            :key="item.extension"
            :value="item.extension"
            :label="item.name"
          />
        </el-select>
        <button type="button" @click="confirm" class="btn accept-btn">
          {{ $t('send') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    formData: {
      type: Object,
      default: () => ({})
    },
    isReport: {
      type: Boolean,
      default: false
    },
    tableId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formats: [
        {
          name: 'pdf',
          extension: 'pdf'
        },
        {
          name: 'word',
          extension: 'word'
        }
      ],
      format: 'pdf',
      isDefaultReports: false,
      isGraphic: true,
      isMap: false,
      currentTables: [],
      dates: [],
      openedReports: [],
      currentUnit: [],
      isPrintMap: false,
      isChart: false
    }
  },
  computed: {
    ...mapState('reports', {
      reportTemplates: (state) => state.templates,
      currentReport: (state) => state.currentReport
    })
  },
  methods: {
    confirm() {
      const { from, to } = this.dates

      const groupTemplates = [...this.openedReports, ...this.currentTables]
        .filter((report) => report.isSelected)
        .map((template) => {
          if (template.key === 'chart') {
            this.isChart = true
          }
          return { id: template.id }
        })

      let unitIds = []
      let units = []
      const finalData = {
        formData: {
          from,
          to,
          template_tables: groupTemplates
        },
        isPrintMap: this.isPrintMap,
        isChart: this.isChart,
        format: this.format
      }
      if (this.openedReports.some((el) => el.unit)) {
        unitIds = [
          ...this.openedReports.map((report) => {
            return report.unit.id
          }),
          this.currentUnit.id
        ]
        const uniqueUnitIds = [...new Set(unitIds)]
        units = uniqueUnitIds.map((id) => ({ id }))
        finalData.formData.units = units
      } else {
        if (this.formData.tableData) {
          unitIds = this.formData.tableData.group_id
        }
        if (this.formData.groups) {
          unitIds = this.formData.groups[0].id
          finalData.formData.groups = [{ id: unitIds }]
        } else if (this.formData.units) {
          finalData.formData.units = this.formData.units
        }
      }

      if (
        finalData.formData.template_tables.length &&
        finalData.formData.units &&
        finalData.formData.units.length
      ) {
        this.$emit('handlePrint', finalData)
      }

      if (
        finalData.formData.template_tables.length &&
        finalData.formData.groups &&
        finalData.formData.groups.length
      ) {
        this.$emit('handlePrint', finalData)
      }

      this.$emit('close')
    }
  },
  created() {
    const openedReportsStr = localStorage.getItem('openedReports')

    if (openedReportsStr) {
      const openedReportsArr = JSON.parse(openedReportsStr)
      this.openedReports = openedReportsArr.map((item) => ({
        name: item.name,
        key: item.key,
        unit: item.unit,
        isSelected: true,
        id: item.id
      }))
    }

    this.dates = {
      from: this.formData.from || this.formData.report.from,
      to: this.formData.to || this.formData.report.to
    }

    const reportTemplate = this.reportTemplates.find(
      (el) => el.id === this.tableId
    )

    const tableId = this.formData.report
      ? this.formData.report.template_tables[0].id
      : ''
    const findedTable = reportTemplate.template_tables.find(
      (el) => el.id === tableId
    )
    const findedTableArray = [findedTable]
    if (findedTable) {
      findedTable.isSelected = true
    }

    // console.log(this.formData, this.formData.groups[0], 'format')

    if (this.formData.currentUnits && this.formData.currentUnits.length) {
      this.currentUnit = this.formData.currentUnits[0]
    }
    if (!this.formData.units) {
      if (this.formData.report) {
        this.currentUnit = this.formData.report.groups[0]
      }
      if (this.formData.groups) {
        this.currentUnit = this.formData.groups[0]
      }
    } else {
      this.currentUnit =
        this.formData.units[0] ||
        this.formData.units ||
        this.formData.tableData.group_id
    }

    if (this.isReport) {
      this.currentTables = this.formData.currenttables
        ? this.formData.currenttables.map((el) => {
            return {
              ...el,
              isSelected: true
            }
          })
        : findedTableArray.map((el) => {
            return {
              ...el,
              isSelected: true
            }
          })
    }
  }
}
</script>
<style scoped lang="stylus">

.dialog-container {
  background-color: #00000067;
  width: 100%;
  height: 100%;
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  z-index: 90
  }
.dialog{
    position: absolute;
    top:50%;
    left:50%;
    transform : translate(-50%,-50%);
    color: #253545;
    width:400px;
    height:600px;
    background: #FFF;

    .header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 25px;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 120%;
      color: #446C9D;
      border-bottom: 1px solid #CAD6DF;

    }
    .content{
      padding: 0 25px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      display: flex;
      justify-content:center;
      flex-direction: column;
      >>> .el-checkbox__input{
        margin-top:10px;
      }
      .input-content {
        height: 56px;
        justify-content: center;
       .title{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #81ABE1;
        transition: 0.3s ease-in-out
      }
       .sub-title{
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 120%;
          color: #81ABE1;
          transition: 0.3s ease-in-out
      }}
    }
    .second{
      display: flex;
      align-items: flex-start;
      justify-content: flex-start
    }
    .footer{
      padding-left:20px;
      padding-right:20px;
      margin-top:20px;
      padding-bottom:10px;
      border-top: 1px solid #CAD6DF;
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: white;
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      background-color: #fff;
      z-index: 9999
      .btn{
        width: 140px;
        height: 32px;
        border-radius: 6px;
        margin-top: 12px;
      }
      .cancel-btn{
        background: #C8D6DF;

      }
      .accept-btn{
        background: #446C9D;;

      }
    }
  }
  .isChecked{
  color: #446C9D !important;
  transition: 0.3s ease-in-out
}
.map{
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 25px;
  span {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
  color: #81ABE1;
  transition: 0.3s ease-in-out
  }
  border-bottom: 1px solid #CAD6DF;
}
.border-main {
  border-bottom: 1px solid #CAD6DF;

}

.main {
  height: 485px;
  overflow: auto;
}
</style>
